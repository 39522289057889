import cs from 'app/theme/colors';

const colors = cs.colors;
import fonts from 'app/theme/fonts';
import { breakpoints } from 'app/theme/breakpoints';
import mixins from 'app/theme/mixins';
import reactTransitionGroupAnimations from 'app/theme/reactTransitionGroupAnimations';

const disabledFormElement = {
  cursor: 'not-allowed',
  backgroundColor: `${colors.formDisabledBg} !important`,
};

// Please don't use any of the global styles the aim is to get this file so it only contains BODY HTML etc.

const paddedMaxWidth = App.Features('global.paddedMaxWidth');
const maxWidth = App.Features('global.maxWidth');
// if padded max width has been set use that
const actualMaxWidth = App.Features('external.enabled')
  ? '100%'
  : paddedMaxWidth || maxWidth;

const globals = {
  html: {
    backgroundColor: `${colors.background} !important`,
    color: !App.Features('external.enabled') && colors.htmlText,
    overflowX: 'hidden',
    ...fonts.base,
    maxWidth: actualMaxWidth,
    margin: 'auto !important',
    fontVariantLigatures: 'none',
    height: '100%',
    overflowY: 'hidden',
    fontSize: App.Features('global.fontSize', undefined),
  },
  a: {
    ...(!App.Features('external.enabled') && {
      ...mixins.linkColor(colors.linkText, colors.linkActiveText),
    }),
    textDecoration: 'none',
  },
  body: {
    marginRight: '0 !important',
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: App.Features('global.bodyScroll') ? 'auto' : 'hidden',
    fontSize: '90%',
    ...(!App.Globals.debug && {
      userSelect: 'none',
    }),
    ...breakpoints()
      .up('lgxl', {
        position: App.Features('external.enabled') && 'fixed',
      })
      .values(),
  },
  figure: {
    margin: 0,
  },
  '#body-content': {
    height: App.Features('global.bodyScroll') ? 'auto' : '100%',
    backgroundColor: `${colors.background} !important`,
    color: App.Features('external.enabled') && colors.htmlText,
    '& > div': {
      height: '100%',
    },
  },
  ...breakpoints()
    .up('lg', {
      '::-webkit-scrollbar-track': {
        borderRadius: '10px',
      },
      '::-webkit-scrollbar': {
        borderRadius: '10px',
        width: '7px',
        height: '7px',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: colors['grey-60'],
        '&:hover': {
          background: colors['grey-70'],
        },
      },
    })
    .values(),
  "ul[class*='g-menu']": {
    padding: '0',
  },
  "[class*='g-menu']": {
    margin: '0',
    listStyle: 'none',

    '& a': {
      display: 'block',
    },
  },
  'g-menu--horizontal': {
    '& li': {
      display: 'inline-block',
      cursor: 'pointer',

      '&:first-child .g-menu-link': {
        marginLeft: '0',
      },
    },
  },
  '.form-section': {
    paddingBottom: '20px',
    minHeight: 'inherit !important',

    '& label': {
      marginBottom: '5px',
      display: 'block',
    },

    '& .form-col': {
      position: 'relative',
    },

    '& .input-icon': {
      position: 'absolute',
      top: '6px',
      right: '12px',
      fontSize: '18px',
    },

    '& .text-over-input': {
      position: 'absolute',
      top: '10px',
      left: '10px',
    },

    '& .gender': {
      '> .label-col': {
        width: '100%',
      },

      '& .form-section': {
        width: '100%',
        dispaly: 'inline-block',
        paddingBottom: '0',

        '& input': {
          marginRight: '10px',
          display: 'inline-block',
          width: 'auto',
        },

        '& label': {
          display: 'inline-block',
          width: 'auto',
        },
      },
    },
  },
  'g-tabs-wrapper': {
    position: 'relative',
    backgroundColor: colors['grey-10'],
    borderBottom: `1px solid ${colors.black}`,

    '&.lozenge': {
      padding: '10px',
    },

    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
    },
  },
  'g-input-field--error': {
    border: `1px solid ${colors.error}`,
    marginBottom: '5px !important',
  },
  'error-info': {
    '& p': {
      color: colors.error,
      margin: 0,
    },
  },
  '.error-box ': {
    '& p': {
      color: colors.error,
      fontSize: App.Variables('registerErrorBox.fontSize', undefined),
    },
  },
  "input:not([type='checkbox']).has-currency": {
    paddingLeft: '41px',
  },
  "input:not([type='checkbox'])": {
    borderRadius: App.Features('globals.borderRadius'),
  },
  'u-always-ltr': {
    direction: 'ltr !important',
  },
  'input:disabled': {
    ...disabledFormElement,
  },
  'select:disabled': {
    ...disabledFormElement,
  },
  ...reactTransitionGroupAnimations,
  '.ReactModal__Body--open': {
    overflow: 'hidden',
    position: 'fixed',
    maxWidth: App.Features('global.maxWidth'),
  },
  '.ReactModal__Content--after-open': {
    outline: 'none',
  },
  '.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap': {
    background: colors.limitHistoryItemRowBg,
  },
  '.ant-tabs-tab-btn': {
    color: colors.limitHistoryItemRowColor,
  },
  '.ant-badge': {
    '-webkit-box-sizing': 'border-box',
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    color: 'rgba(0,0,0,.65)',
    fontSize: '14px',
    fontVariant: 'tabular-nums',
    lineHeight: 1.5,
    listStyle: 'none',
    '-webkit-font-feature-settings': 'tnum',
    'font-feature-settings': 'tnum',
    position: 'relative',
    display: 'inline-block',
  },
  '.ant-badge-count': {
    minWidth: '16px',
    height: '16px',
    padding: '0 6px',
    color: '#fff',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    background: '#f5222d',
    borderRadius: '8px',
    '-webkit-box-shadow': '0 0 0 1px #fff',
    boxShadow: '0 0 0 1px #fff',
  },

  '.ant-badge-count a,.ant-badge-count a:hover': {
    color: '#fff',
  },

  '.ant-badge-multiple-words': {
    padding: '0 8px',
  },

  '.ant-badge-dot': {
    width: '6px',
    height: '6px',
    background: '#f5222d',
    borderRadius: '100%',
    '-webkit-box-shadow': '0 0 0 1px #fff',
    boxShadow: '0 0 0 1px #fff',
  },
  '.ant-badge-count,.ant-badge-dot,.ant-badge .ant-scroll-number-custom-component': {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    '-webkit-transform': 'translate(50%,-50%)',
    '-ms-transform': 'translate(50%,-50%)',
    transform: 'translate(50%,-50%)',
    '-webkit-transform-origin': '100% 0%',
    '-ms-transform-origin': '100% 0%',
    transformOrigin: '100% 0%',
  },

  '.ant-badge-status': {
    lineHeight: 'inherit',
    verticalAlign: 'baseline',
  },

  '.ant-badge-status-dot': {
    position: 'relative',
    top: '-1px',
    display: 'inline-block',
    width: '6px',
    height: '6px',
    verticalAlign: 'middle',
    borderRadius: '50%',
  },

  '.ant-badge-status-success': {
    backgroundColor: '#52c41a',
  },

  '.ant-badge-status-processing': {
    position: 'relative',
    backgroundColor: '#1890ff',
  },

  '.ant-badge-status-processing::after': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: '1px solid #1890ff',
    borderRadius: '50%',
    '-webkit-animation': 'antStatusProcessing 1.2s infinite ease-in-out',
    animation: 'antStatusProcessing 1.2s infinite ease-in-out',
    content: '',
  },
};

export default globals;
