import { observable, action, computed, makeObservable } from 'mobx';
import deviceState from 'stores/DeviceState';

class ViewportState {
  constructor() {
    makeObservable(this);
    this.handleResize = _.throttle(() => this.calculateResize(), 20);

    window.addEventListener('resize', ::this.handleResize);
    window.addEventListener(this.supportsOrientationChange, this.handlePrevent);
    this.handlePrevent();
  }

  @observable windowWidth = document.documentElement.clientWidth;
  @observable windowHeight = document.documentElement.clientHeight;

  @observable screenWidth = window.screen.width;
  @observable screenHeight = window.screen.height;

  @observable isLandscape = false;
  defaultBreakpoints = {
    xs: 0,
    xssm: 400,
    sm: 544,
    md: 768,
    lg: 991,
    lgxl: 1024,
    xl: 1366,
    xxl: 2140,
  };

  @observable breakpoints = this.defaultBreakpoints;

  fixIframeBody = () => {
    if (
      window.parent &&
      this.isMobile &&
      window.location !== window.parent.location
    ) {
      document.body.style.width = '100vw';
      document.body.style.height = '100vh';
    }
  };

  @computed
  get isMobileLandscape() {
    return (
      this.windowHeight < this.windowWidth && this.md && this.windowHeight < 768
    );
  }

  @computed
  get xssm() {
    return this.windowWidth < this.breakpoints.xssm;
  }

  @computed
  get sm() {
    return this.windowWidth < this.breakpoints.sm;
  }

  @computed
  get md() {
    return this.windowWidth < this.breakpoints.md;
  }

  @computed
  get lg() {
    return this.windowWidth < this.breakpoints.lg;
  }

  @computed
  get lgxl() {
    return this.windowWidth < this.breakpoints.lgxl;
  }

  @computed
  get lgmdxl() {
    return this.windowWidth < 1250;
  }

  @computed
  get xl() {
    return this.windowWidth < this.breakpoints.xl;
  }

  @computed
  get xxl() {
    return this.windowWidth < this.breakpoints.xxl;
  }

  @computed
  get isMobile() {
    return this.isSmallerThanBreakpoint('sm');
  }

  @computed
  get isTablet() {
    return !this.isSmallerThanBreakpoint('sm') && this.windowWidth <= 1024;
  }

  @computed
  get isIphoneX() {
    // Really basic check for the ios platform
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Get the device pixel ratio
    const ratio = window.devicePixelRatio || 1;

    // Define the users device screen dimensions
    const screen = {
      width: this.screenWidth * ratio,
      height: this.screenHeight * ratio,
    };

    // iPhone X Detection
    return iOS && screen.width == 1125 && screen.height === 2436;
  }

  @computed
  get isBot() {
    return /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/.test(
      navigator.userAgent,
    );
  }

  @computed
  get isIphone5() {
    return (
      this.isIphone &&
      window.devicePixelRatio >= 2 &&
      screen.availHeight === 568
    );
  }

  @computed
  get isIphone() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('iphone') > -1;
  }

  @computed
  get isIpad() {
    const userAgent = navigator.userAgent;
    return userAgent.indexOf('iPad') > -1;
  }

  @computed
  get isMac() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('macintosh') > -1;
  }

  @computed
  get isApple() {
    return this.isIphone || this.isMac || this.isIpad;
  }

  @computed
  get showHiddenBetslip() {
    return this.windowWidth < App.Features('betslip.slideBreakpoints');
  }

  get supportsOrientationChange() {
    return 'onorientationchange' in window ? 'orientationchange' : 'resize';
  }

  @computed
  get angleCurrent() {
    try {
      return screen.orientation.angle;
    } catch (e) {
      return window.orientation;
    }
  }

  handlePrevent = () => {
    this.isLandscape = this.angleCurrent !== 0;
  };

  @computed
  get isWebView() {
    return !(
      this.isMobile ||
      this.isTablet ||
      deviceState.isAndroid ||
      deviceState.isIphone
    );
  }

  @action
  calculateResize = () => {
    this.windowWidth = document.documentElement.clientWidth;
    this.windowHeight = document.documentElement.clientHeight;

    this.screenWidth = window.screen.width;
    this.screenHeight = window.screen.height;
  };

  isSmallerThanBreakpoint = breakpoint => this[breakpoint];

  @action
  setBreakPoints = () => {
    import(/* webpackChunkName: "breakpoints" */ 'app/theme/breakpoints').then(
      module => {
        const breakpoints = module?.breakpoints()?.breakpointValues();
        if (breakpoints) {
          this.breakpoints = breakpoints;
        }
      },
    );
  };

  iosVersion = () => {
    const match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    let version;

    if (match !== undefined && match !== null) {
      version = [
        parseInt(match[1], 10),
        parseInt(match[2], 10),
        parseInt(match[3] || 0, 10),
      ];
      return parseFloat(version.join('.'));
    }
    return false;
  };

  androidVersion = () => {
    const ua = navigator.userAgent.toLowerCase();
    const match = ua.match(/android\s([0-9\.]*)/i);
    return match ? parseFloat(match[1]) : false;
  };

  @computed
  get browserType() {
    const userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else {
      browserName = 'No browser detection';
    }

    return browserName;
  }
}

const store = new ViewportState();
export default store;
