import { runInAction, extendObservable } from 'mobx';
import service from 'common/service/SessionService';
import querystring from 'querystring';
import messagesStore from 'app/stores/messages';
import Cookies from 'stores/CookieStore';
import menuState from 'stores/MenuState';
import { isEmpty, isNil } from 'lodash';

export const loadAsync = (url, callBack, cacheBust = false, dataset = null) => {
  let urlRef = url;

  return new Promise((resolve, reject) => {
    if (cacheBust) {
      urlRef = `${urlRef}?cb=${new Date().getTime()}`;
    }

    const doc = document;
    const isCss = urlRef.includes('.css');
    const eleType = (isCss && 'link') || 'script';
    const ele = doc.createElement(eleType);
    const selector = doc.getElementsByTagName(eleType)[0];

    if (eleType === 'link') {
      ele.href = `${urlRef}`;
      ele.rel = 'stylesheet';
    } else {
      ele.src = `${urlRef}`;
      ele.type = 'text/javascript';
    }

    if (callBack) {
      ele.addEventListener('error', reject, true);
      ele.addEventListener(
        'load',
        e => {
          resolve(e);
          callBack(null, e);
        },
        false,
      );
    }

    if (dataset) {
      Object.keys(dataset).forEach(key => {
        ele.dataset[key] = dataset[key];
      });
    }

    selector.parentNode.insertBefore(ele, selector);
  });
};

// source *  https://www.sitepoint.com/get-url-parameters-with-javascript/
export const getAllUrlParams = url => {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  const obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=');

      // in case params look like: list[]=thing1&list[]=thing2
      var paramNum = undefined;
      let paramName = a[0].replace(/\[\d*\]/, v => {
        paramNum = v.slice(1, -1);
        return '';
      });

      // set parameter value (use 'true' if empty)
      const paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();

      // if parameter name already exists
      if (obj[paramName]) {
        // convert value to array (if still string)
        if (typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]];
        }
        // if no array index number specified...
        if (typeof paramNum === 'undefined') {
          // put the value on the end of the array
          obj[paramName].push(paramValue);
        } else {
          // if array index number specified...
          // put the value at that index number
          obj[paramName][paramNum] = paramValue;
        }
      } else {
        // if param name doesn't exist yet, set it
        obj[paramName] = paramValue;
      }
    }
  }

  return obj;
};

export const sleep = time => new Promise(resolve => setTimeout(resolve, time));

export const setApplicationName = () => {
  switch (true) {
    case App.Globals.debug:
      App.Config.application = 'web-sportsbook-debug';
      break;
    case App.isRetail && App.Features('retail.appName'):
      App.Config.application = 'retail-sportsbook';
      // move this to be a server override
      App.Globals.channelCode = 'SBTRETAIL_MASTER';
      App.Globals.brandCode = 'SBTRETAIL';
      break;
    case window.isNative:
      App.Config.application = 'app-sportsbook';
      break;
    default:
      App.Config.application = 'web-sportsbook';
      break;
  }
};

export const convertToObservable = (model, attributes) => {
  runInAction(() => {
    const obAttributes = _.pick(model.attributes, attributes);
    attributes.forEach(key => delete model.attributes[key]);
    extendObservable(model.attributes, obAttributes);
  });
};

export const parseQueryString = location => {
  const search = location && location.search;
  let query = null;
  if (search) {
    query = querystring.parse(search.replace('?', ''));
  }
  if (!query) {
    return;
  }
  const { ats_setCookie, ats_token, ext_token, ats_kyc, ...rest } = query;

  if (ats_token) {
    service.singleSignOnLogin(true, ats_token).then(resp => {
      App.session.execute('storeSession', resp.Login);
    });
  }

  if (ats_kyc) {
    menuState.modals.verificationOverlay.open();
  }

  if (ats_setCookie) {
    const keys = Object.keys(rest);
    if (keys && keys.length) {
      keys.forEach(key => {
        key = key.replace('amp;', '');
        Cookies.set(key, rest[key]);
      });
    }
  }
};

export const loadInitSession = () => {};

export const openPopup = (popupURL, event) => {
  event && event.preventDefault();

  const options = {
    toolbar: 'no',
    location: 'no',
    directories: 'no',
    status: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'yes',
    width: 980,
    height: 800,
    top: (o, w) => (w.innerHeight - o.height) / 2 + w.screenY,
    left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX,
  };

  const optionsString = _.reduce(
    options,
    (acc, opt, key) => {
      let val;
      if (_.isFunction(opt)) {
        val = opt(options, window);
      } else {
        val = opt;
      }
      const part = `${key}=${val}`;
      return !acc ? part : `${acc},${part}`;
    },
    '',
  );

  window.open(popupURL, 'Popup_Window', optionsString);
};

export const lazyImport = (func, name, comp) => {
  if (!func) {
    return null;
  }
  return new Promise((resolve, reject) => {
    if (comp && name && comp[name]) {
      resolve(comp[name]);
      return;
    }
    func.then(resp => {
      const def = resp && resp.default;
      if (def) {
        if (comp && name) {
          runInAction(() => {
            comp[name] = def;
          });
        }
        resolve(def);
      }
    });
  });
};

export const capitalizeWords = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const toBoolean = (data, keys = []) => {
  keys.forEach(key => {
    if (data.hasOwnProperty(key)) {
      data[key] = !!data[key];
    }
  });
};

export const getTrackingName = el => {
  let result = '';
  while (el) {
    if (el.dataset.trackingName) {
      const trackingName =
        el.dataset.trackingName.charAt(0).toUpperCase() +
        el.dataset.trackingName.slice(1);
      result = trackingName + result;
    }
    el = el.parentElement;
  }
  return result.replace(/[^A-Z0-9]+/gi, '');
};

export const getQueryString = (filterOutDeposit = false) => {
  const search = window?.location?.search;
  let query = {};
  if (search) {
    query = querystring.parse(search.replace('?', ''));
  }
  if (filterOutDeposit && query.rid) {
    delete query.rid;
  }
  return query;
};

export const getQueryStringParam = param => {
  const searchQuery = getQueryString();
  const matchedParam =
    searchQuery[
      Object.keys(searchQuery).find(
        key => key.toLowerCase() === param.toLowerCase(),
      )
    ];

  return matchedParam ? decodeURI(matchedParam) : undefined;
};

export const checkRedirectCondition = conditionType => {
  const redirectConditions = {
    messages: !messagesStore.isSendingMessage,
  };

  return redirectConditions[conditionType] || false;
};

export const getBonusCodeFromUrl = queryParams => {
  const params = queryParams.substr(1).split('&');
  let code = '';
  const codeIndex = params.findIndex(param => {
    const keyVal = param.split('=');
    return keyVal[0] === 'code';
  });
  if (codeIndex > -1) {
    const keyValue = params[codeIndex].split('=');
    code = (keyValue.length > 1 && keyValue[1]) || '';
  }
  return code;
};

export const hashCode = s =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export const getObjectKeyByValue = (object, value) => {
  for (const element of object) {
    if (element?.value === value) {
      return element.key;
    }
  }
  return undefined;
};

export const createObjectWithValuablePairFromObject = object =>
  Object.fromEntries(
    Object.entries(object).filter(([key, value]) => !isNil(value)),
  );

export const createArgsFrom = params => {
  const args = createObjectWithValuablePairFromObject(params);
  if (isEmpty(args)) return '';
  return `?${new URLSearchParams(params).toString()}`;
};

export const getQueryParams = str => {
  const queryStringRegex = /[?&]([^?&=]+)=([^?&=]*)/g;
  const queryParams = {};
  str.replace(queryStringRegex, (match, key, value) => {
    const keyName = decodeURIComponent(key);
    const keyValue = decodeURIComponent(value);
    queryParams[keyName] = keyValue;
  });
  return queryParams;
};
