import { observable, makeObservable } from 'mobx';
import { loadAsync } from 'app/util/Utils';

class AdnxsPixelTracking {
  @observable
  searchText = '';

  track = (type = '', { amount = 0 }) => {
    const pixelTrackingId = App.Features('tracking.adnxs.id');
    const accountId = App.session.request('accountId');
    const eventType = App.Features(
      `tracking.adnxs.types.${type.split('_')[0]}`,
    );
    if (!pixelTrackingId || !accountId || !eventType) {
      return;
    }
    let params = eventType;
    if (params.includes('[ACCOUNT_ID]')) {
      params = params.replace('[ACCOUNT_ID]', accountId);
    }
    if (params.includes('[AMOUNT]')) {
      params = params.replace('[AMOUNT]', amount);
    }

    if (params) {
      loadAsync(`https://secure.adnxs.com/px?id=${pixelTrackingId}&${params}`);
    }
  };

  constructor() {
    makeObservable(this);
  }
}

const store = new AdnxsPixelTracking();
export default store;
