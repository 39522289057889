const TYPE_MAP = {
  login_clicked: 'login_clicked',
  deposit_success: 'deposit_success',
  withdraw_success: 'withdraw_success',
  register_success: 'register_success',
  register_submitted: 'register_submitted',
  register_verifyid: 'register_verifyid',
  placebet_clicked: 'placebet_clicked',
  placebet_success: 'placebet_success',
  placebet_failed: 'placebet_failed',
  joinnow_clicked: 'joinnow_clicked',
};

const DEEPLINK_ROUTE_VALUES = ['home', 'promotions', 'sports'];

class AppsFlyer {
  init = async () => {
    const hasSmartBanners = App.Features('tracking.appsFlyer.smartBanners');
    const appsflyer = hasSmartBanners
      ? await import('thirdparty/appsflyer/appsflyerSmartBanners')
      : await import('thirdparty/appsflyer/appsflyer');
    if (appsflyer) {
      const initialise = appsflyer?.default;
      if (hasSmartBanners) {
        initialise?.(
          App.Features('tracking.appsFlyer.webKey'),
          App.Features('tracking.appsFlyer.smartBannerKey'),
        );
        const appsflyerOneLinkSmartScript = await import(
          'thirdparty/appsflyer/appsflyerOneLinkSmartScript'
        );
        const smartScript = appsflyerOneLinkSmartScript?.default;
        smartScript?.();
      } else {
        initialise?.(App.Features('tracking.appsFlyer.webKey'));
      }
    }
  };

  track = async (key, { amount = null }) => {
    if (!TYPE_MAP[key]) {
      return;
    }
    if (!window.AF) {
      await this.init();
    }
    if (amount) {
      window.AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: key,
        eventRevenue: amount,
      });
    }
    window.AF('pba', 'event', { eventType: 'EVENT', eventName: key });
  };

  identify = async userId => {
    window.AF('pba', 'setCustomerUserId', String(userId));
  };
}

const inst = __FEATURES__.tracking.appsFlyer.enabled && new AppsFlyer();
export default inst;
