import { observable, computed, action, observe, makeObservable } from 'mobx';

class AirshipStore {
  @observable
  channel;

  addLoggedInListener = async () => {
    const session = await import('app/stores/session');
    if (session?.default) {
      observe(session?.default, 'loggedIn', this.setNamedUser);
    }
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get optInStatus() {
    return this?.channel?.optedIn;
  }

  @action
  init = async () => {
    const pkg = await import('thirdparty/airship');
    if (pkg) {
      const initialise = pkg?.default;
      initialise(App.Features(`tracking.airship.key.${App.Globals.env}`));

      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          this.channel = channel;
          if (App.session.request('loggedIn')) {
            this.setNamedUser();
          }
        });
      });
      this.addLoggedInListener();
    }
  };

  setNamedUser = () => {
    window.UA.then(sdk => {
      if (App.session.request('loggedIn')) {
        sdk?.channel?.namedUser?.set(App.session.request('accountId'));
      }
    });
  };

  afterLogin = () => {
    // show permission modal
    window.UA.then(sdk => {
      sdk.plugins
        .load(
          'html-prompt',
          'https://aswpsdkus.com/notify/v1/ua-html-prompt.min.js',
          {
            askAgainDelay: 604800, // 1 week
            auto: true,
          },
        )
        .then(plugin => plugin.prompt());
    });
  };

  @action
  optOut = async () => {
    await this.channel.optOut();
    window.UA.then(sdk => {
      sdk.getChannel().then(channel => {
        this.channel = channel;
      });
    });
  };

  @action
  optIn = () => {
    window.UA.then(sdk => {
      sdk.register().then(channel => {
        this.channel = channel;
      });
    });
  };
}
const inst = __FEATURES__.tracking.airship.enabled && new AirshipStore();
export default inst;
