import { computed, makeObservable } from 'mobx';
import Menu from './Menu';
import viewportState from 'stores/ViewportState';
import SectionController from './SectionController';

class BreadcrumbMenu {
  _sport = new Menu();
  _country = new Menu();
  _competition = new Menu();
  _event = new Menu();

  sport = new SectionController(this, '_sport');
  country = new SectionController(this, '_country');
  competition = new SectionController(this, '_competition');
  event = new SectionController(this, '_event');

  closeAll = () => {
    this._sport.close();
    this._country.close();
    this._competition.close();
    this._event.close();
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get lockBody() {
    const sectionOpen =
      this.sport.isOpen ||
      this.country.isOpen ||
      this.competition.isOpen ||
      this.event.isOpen;
    return sectionOpen && viewportState.isSmallerThanBreakpoint('md');
  }
}

export default BreadcrumbMenu;
