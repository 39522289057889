import { action, observable, makeObservable } from 'mobx';
import viewportState from 'stores/ViewportState';

const ua = navigator.userAgent;
const platform = navigator.platform;

class DeviceState {
  isIphone =
    ua.match(/ipad|ipod|iphone/i) ||
    (platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  isFirefox = ua.toLowerCase().includes('firefox');
  isEdge = ua.toLowerCase().includes('edge');
  isWindows = ua.toLowerCase().includes('windows');
  isLinux = ua.toLowerCase().includes('linux');
  isMac = ua.toLowerCase().includes('macintosh');
  isAndroid = /android/i.test(ua);

  constructor() {
    makeObservable(this);
    window.addEventListener('touchstart', this.onFirstTouch, false);
  }

  @observable
  hover = true;

  @action
  onFirstTouch = () => {
    this.hover = false;
    window.removeEventListener('touchstart', this.onFirstTouch, false);
  };

  get android() {
    return /Android/i.test(ua);
  }

  get linux() {
    return /Linux/i.test(ua) && !this.android;
  }

  get mac() {
    return /Mac OS X/i.test(ua) && !this.iphone;
  }

  get windows() {
    return /Windows NT/i.test(ua);
  }

  get ios() {
    return this.iphone || this.iPad;
  }

  get osType() {
    if (this.ios) return 'IOS';
    if (this.mac) return 'OSX';
    if (this.windows) return 'WINDOWS';
    if (this.linux) return 'LINUX';
    if (this.android) return 'ANDROID';
    return 'OTHER';
  }

  get buildDeviceDetails() {
    const deviceDetails = App.Features('register.useBasicDeviceContext')
      ? `site=${viewportState.isMobile ? 'MOBILE' : 'NORMAL'}`
      : `site=${viewportState.isMobile ? 'MOBILE' : 'NORMAL'};os-type=${
          this.osType
        }`;
    return deviceDetails;
  }
}

const store = new DeviceState();
export default store;
