import trackingStore from 'app/stores/trackingStore.js';

class OptimoveStore {
  init = async () => {
    if (App.Features('tracking.optimove.enabled')) {
      const optimove = await import('thirdparty/optimove');
      if (optimove) {
        const initialise = optimove?.default;
        initialise(
          App.Features(
            `tracking.optimove.keys.${
              App.Globals.isDevUat ? 'dev' : 'prod'
            }.tenantId`,
          ),
          App.Features(
            `tracking.optimove.keys.${
              App.Globals.isDevUat ? 'dev' : 'prod'
            }.tenantToken`,
          ),
        );
      }
    }
  };

  track = async (eventName = '', metaData = {}) => {
    if (!window.optimoveSDK) {
      await this.init();
    }
    window.optimoveSDK.API.reportEvent(eventName, metaData);
  };

  setUserEmail = async (email = '') => {
    if (!window.optimoveSDK) {
      await this.init();
    }
    window.optimoveSDK.API.setUserEmail(email);
  };

  setUserID = async (sdkID = '') => {
    if (!window.optimoveSDK) {
      await this.init();
    }
    if (sdkID !== undefined && sdkID !== null && sdkID !== '') {
      window.optimoveSDK.API.setUserId(sdkID?.toString());
    }
  };

  registerUser = async (sdkID = '', email = '') => {
    if (!window.optimoveSDK) {
      await this.init();
    }
    window.optimoveSDK.API.registerUser(sdkID?.toString(), email);
  };

  setPageVisit = async (pageUrl = '', pageTitle = '') => {
    if (!trackingStore.trackingEnabled) {
      return;
    }
    if (!window.optimoveSDK) {
      await this.init();
    }
    window.optimoveSDK.API.setPageVisit(pageUrl, pageTitle);
  };
}

const inst = new OptimoveStore();
export default inst;
