import { observable, action, makeObservable, computed } from 'mobx';

class Messages {
  @observable isSendingMessage = false;
  @observable unreadMessages = 0;

  @observable unreadInboxMessagesCount;

  @action
  setSendingMessageStatus = val => {
    this.isSendingMessage = val;
  };

  constructor() {
    makeObservable(this);
  }

  @action
  changeUnreadInboxMessagesCount = val => {
    if (this.unreadInboxMessagesCount !== val)
      this.unreadInboxMessagesCount = val;
  };

  @computed
  get isUnread() {
    return this.unreadMessages > 0;
  }
}

const store = new Messages();
export default store;
