const fontFaces = {
  GoldenYouthCaps: [
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      srcPaths: ['/fonts/GoldenYouthCaps/Golden-Youth-Caps.ttf'],
    },
  ],
  OpenSansCondensed: [
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      srcPaths: ['/fonts/OpenSans/OpenSans_Condensed-Regular.ttf'],
    },
  ],
  OpenSansCondensedBold: [
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      srcPaths: ['/fonts/OpenSans/OpenSans_Condensed-Bold.ttf'],
    },
  ],
  OpenSansCondensedMedium: [
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      srcPaths: ['/fonts/OpenSans/OpenSans_Condensed-Medium.ttf'],
    },
  ],
};
export default fontFaces;
