import store from 'store';
import { getQueryStringParam } from 'app/util/Utils';
import Cookies from 'stores/CookieStore';

const PARAM_NAMES = {
  BTAG: 'bTag',
  SREF: 'sref',
  DEFAULTAFF: 'aff',
  AFFILIATE_BONUS_CODE: 'payload',
};

const STORE_NAMES = {
  BTAG: 'btag',
  AFFILIATE: 'affiliateParameter',
  AFFILIATE_BONUS_CODE: 'affiliateBonusCode',
  AFFILIATE_URL: 'affiliateUrl',
};

/**
 * Get the current bTag or use previous, defaults to null
 * e.g. ?bTag=654557_D92E6FF69AE845C983D1BE696BE9C010&sref=ref&ref=123354
 *
 * Also covers extracting a bonusCode from url query param
 * e.g. ?btag=xxx&aff=yyy&payload=bonusCode123'
 */
class AffiliatesController {
  constructor() {
    this.init();
  }

  /*
   * Removes both Cookies and Local Storage for legacy
   */
  init() {
    const bTag = getQueryStringParam(PARAM_NAMES.BTAG);
    const sref = getQueryStringParam(PARAM_NAMES.SREF);
    const affiliateBonusCode = getQueryStringParam(
      PARAM_NAMES.AFFILIATE_BONUS_CODE,
    );
    let affId;

    if (sref) {
      // The URL provides the query string param name for the affiliate ID
      affId = getQueryStringParam(sref);
    } else {
      // Default attempt to use 'aff' for the query string param name
      affId = getQueryStringParam(PARAM_NAMES.DEFAULTAFF);
    }

    // if user comes with new bTag replace existing
    if (bTag) {
      this.setReferer(STORE_NAMES.BTAG, bTag);
      if (!affId) this.removeStore(STORE_NAMES.AFFILIATE);
      if (!affiliateBonusCode)
        this.removeStore(STORE_NAMES.AFFILIATE_BONUS_CODE);
    }

    // If user comes with affId value of param, otherwise remove
    if (affId) {
      this.setReferer(STORE_NAMES.AFFILIATE, affId);
      if (!bTag) this.removeStore(STORE_NAMES.BTAG);
      if (!affiliateBonusCode)
        this.removeStore(STORE_NAMES.AFFILIATE_BONUS_CODE);
    }

    // If user comes with new bonus parameter replace existing
    if (affiliateBonusCode) {
      this.setReferer(STORE_NAMES.AFFILIATE_BONUS_CODE, affiliateBonusCode);
      if (!bTag) this.removeStore(STORE_NAMES.BTAG);
      if (!affId) this.removeStore(STORE_NAMES.AFFILIATE);
    }
  }

  /**
   * Clear Stores
   * Removes BTag, Affiliate ID, Affiliate Bonus Code and Affiliate URL stores
   */
  clearStores() {
    this.removeStore(STORE_NAMES.BTAG);
    this.removeStore(STORE_NAMES.AFFILIATE);
    this.removeStore(STORE_NAMES.AFFILIATE_BONUS_CODE);
    this.removeStore(STORE_NAMES.AFFILIATE_URL);
  }

  /**
   * Removes store and cookies
   * @param {string} storeName  The name of the store parameter to remove
   */
  removeStore(storeName) {
    Cookies.remove(storeName);
    store.remove(storeName);
  }

  /**
   * Set the referer cookie
   * @param  {String} name    The cookie name
   * @param  {String} value   The cookie value
   */
  setReferer(name, value) {
    store.set(STORE_NAMES.AFFILIATE_URL, window.location.href, { expires: 30 });
    store.set(name, value, { expires: 30 });
  }

  /**
   * Get the referer cookie
   * @param  {String} name    The cookie name
   * @return {String}         The cookie value
   */
  getCookie(name) {
    return store.get(name) || undefined;
  }
}

const inst = new AffiliatesController();
export default inst;
