import { getAnalytics, logEvent } from 'firebase/analytics';
import { observable } from 'mobx';

class FirebaseStore {
  @observable
  analytics = undefined;

  init = async () => {
    const firebase = await import('thirdparty/firebase');
    if (firebase) {
      this.analytics = getAnalytics(firebase.default);
    }
  };

  track = async (eventName = '', metaData = {}) => {
    if (!this.analytics) {
      return;
    }

    logEvent(this.analytics, eventName, metaData);
  };
}

const store = new FirebaseStore();
export default store;
