class BeaconsStore {
  get configs() {
    return App.Features(
      `beacons.${App?.Globals?.isDevUat ? 'dev' : 'prod'}`,
      {},
    );
  }

  injectBeaconsScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${this.configs.url}?v=${new Date().toISOString()}`;
    document.head.appendChild(script);
  };
}

const store = new BeaconsStore();
export default store;
