const eventMap = {
  pageview: 'pageview',
  deposit: 'deposit',
  firstDepositConfirm: 'firstDepositConfirm',
  registerStart: 'registerStart',
  registerComplete: 'registerComplete',
};

window.dataLayer = window.dataLayer || [];

class GtmTracking {
  track = (event, data = {}) => {
    if (!App.Features('tracking.gtm.enabled')) {
      return;
    }

    window.dataLayer.push(this.getEventProps(event, data));
  };

  getEventProps = (event, data) => {
    switch (event) {
      case 'deposit_success':
        return {
          event: 'deposit_success',
          id: data?.id,
          type: data?.transactionNumber === 1,
          value: data?.amount,
        };

      case 'placebet_success':
        return {
          event: 'bet_confirm',
          amount: data?.amount,
          id: data?.id,
        };
      case 'login_success':
        return {
          event: 'login_success_with_id',
          id: data?.id,
        };

      case 'register_success':
        return {
          event: 'registration_success',
          id: data?.id,
        };

      case 'navigate':
        return {
          event: 'navigate',
          route: pagePath,
        };
      default:
        return {
          event,
        };
    }
  };

  page = pathname => {
    this.track('pageview', { pagePath: pathname });
    if (pathname.split('/').pop() === 'register') {
      this.track('registerStart');
    }
  };
}

const store = new GtmTracking();
export default store;
