const dimensions = {
  promotionTileHeight: '260px',
  selectInputHeight: '2.5rem',
  inputHeight: '47px',
  loginFormHeight: '600px',
  cellWidth: App.Features('markets.displaySelectionNames', false, false)
    ? 200
    : !!App.Features('selectionView.overrideCellWidth')
    ? App.Features('selectionView.overrideCellWidth')
    : 60,
  cellWidthMobile: App.Features('markets.displaySelectionNames', false, false)
    ? 150
    : App.Features('selectionView.overrideCellWidthMobile', 50),
  cellHeightVertical: App.Features(
    'selectionView.overrideCellHeightMobile',
    '40px',
  ),
  cellHeightAsian: '23px',
};

export default dimensions;
