import aesthetic from 'app/theme/aesthetic';

aesthetic.extendTheme('base', 'sportsbook', {
  // any component overides live in here
  headerMinimalView: {
    logo: {
      url: '/logos/logo.png',
      bgSize: 110,
      height: 110,
      width: 80,
    },
  },
  headerView: {
    logo: {
      width: 114,
      url: '/logos/logo.png',
    },
  },
  font: {
    weight: {
      extraLight: '700',
    },
  },
});
