import { observable, computed, observe, makeObservable } from 'mobx';
import Menu from './Menu';
import features from 'config/features';
import viewportState from 'stores/ViewportState';

class LeftNavBetslip {
  constructor(burger, currentOpenState = false) {
    makeObservable(this);
    this.burger = burger;
    if (currentOpenState) {
      this.open();
    }
  }

  @computed
  get isOpen() {
    return this.burger.betslip.isOpen && this.burger.isOpen;
  }

  open = () => {
    this.burger.open();
    this.burger.betslip.open();
  };

  toggle = () => {
    if (!this.isOpen) {
      this.open();
    } else {
      this.close();
    }
  };

  close = () => {
    this.burger.betslip.close();
    this.burger.close();
  };
}

class BetslipMenu {
  @observable betslipStrategy = new Menu();

  constructor(burger) {
    makeObservable(this);
    this.burger = burger;

    if (_.get(features, 'betslip.inLeftNav')) {
      if (viewportState.isSmallerThanBreakpoint('md')) {
        this.useLeftNavStrategy();
      }

      observe(
        viewportState,
        'md',
        value => {
          if (value.newValue) {
            this.burger.close();
            this.useDefaultStrategy();
          } else {
            this.useLeftNavStrategy();
          }
        },
        true,
      );
    }
  }

  useDefaultStrategy = () => {
    const currentOpenState = this.betslipStrategy.isOpen;
    this.betslipStrategy = new Menu(currentOpenState);
  };
  useLeftNavStrategy = () => {
    const currentOpenState = this.betslipStrategy.isOpen;
    this.betslipStrategy = new LeftNavBetslip(this.burger, currentOpenState);
  };

  @computed
  get isOpen() {
    return this.betslipStrategy.isOpen;
  }

  toggle = () => {
    this.betslipStrategy.toggle();
  };

  close = () => {
    this.betslipStrategy.close();
  };

  open = () => {
    // only open betslip if is a hidden betslip
    if (viewportState.showHiddenBetslip) {
      this.betslipStrategy.open();
    }
  };

  @computed
  get lockBody() {
    return this.isOpen && viewportState.isSmallerThanBreakpoint('md');
  }
}

export default BetslipMenu;
