import viewportState from 'stores/ViewportState';
import { observable, action, computed, makeObservable } from 'mobx';
import Menu from './Menu';
import SectionController from './SectionController';

const betslipInLeftNav = __FEATURES__.betslip.inLeftNav;

class BurgerMenu {
  _betslip = new Menu();
  _favourites = new Menu(!betslipInLeftNav);
  _allSports = new Menu(true);
  _raceCards = new Menu();
  _quickLinks = new Menu(true);

  constructor() {
    makeObservable(this);
    this.allSports = betslipInLeftNav
      ? new SectionController(this, '_allSports')
      : {
          isOpen: undefined,
          toggle: undefined,
          open: undefined,
          close: undefined,
        };

    this.raceCards = {
      isOpen: undefined,
      toggle: undefined,
      open: undefined,
      close: undefined,
    };
  }

  @observable
  isOpen = false;

  @action
  toggle = () => {
    this.isOpen = !this.isOpen;
  };

  @action
  open = () => {
    this.isOpen = true;
  };

  @action
  close = () => {
    this.isOpen = false;
  };

  closeAll = () => {
    this._betslip.close();
    this._favourites.close();
    this._allSports.close();
    this._raceCards.close();
    this._quickLinks.close();
  };

  @computed
  get lockBody() {
    return this.isOpen && viewportState.isSmallerThanBreakpoint('lgxl');
  }

  betslip = new SectionController(this, '_betslip');
  favourites = new SectionController(this, '_favourites');
  quickLinks = new SectionController(this, '_quickLinks');
}

export default BurgerMenu;
