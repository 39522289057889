class FacebookPixelTracking {
  NAME = 'facebookPT';

  constructor() {}
  track = (type = '') => {
    let url = null;
    switch (type) {
      case 'first_deposit_success':
        url = App.Features('tracking.facebookPT.deposit') || '';
        break;
      case 'register_success':
        url = App.Features('tracking.facebookPT.register') || '';
        break;
    }
    return url;
  };
}

const store = new FacebookPixelTracking();
export default store;
