import SocketService from 'common/service/SocketService.js';

export const EVENT_TRADING_STATE = 'streaming:eventTradingState';
export const INCIDENTS = 'streaming:incidents';
export const EVENT = 'streaming:event';
export const DATA_SYNC = 'streaming:eventDataSync';
export const SUBSCRIBE_RESPONSE = 'streaming:subscribeResponse';
export const SCHEDULE_AMENDMENT = 'streaming:scheduleAmendment';
export const ACCOUNT_BALANCE_UPDATE = 'streaming:accountBalanceUpdate';
export const OPEN_BETS_COUNT_UPDATE = 'streaming:openBetsCount';
export const BET_UPDATE = 'streaming:betUpdate';
export const OVER_ASK_RESPONSE = 'streaming:overAskResponse';
export const REGISTRATION_STATUS_UPDATE =
  'streaming:AccountRegistrationStatusUpdate';
export const PAYMENT_REGISTRATION_UPDATE =
  'streaming:paymentRegistrationUpdate';
export const DEPOSIT_COMPLETION_UPDATE = 'streaming:depositCompletionUpdate';
export const CALCULATE_CASHOUT = 'streaming:calculateCashout';
export const VOUCHER_MONEY_UPDATE = 'streaming:voucherMoneyUpdate';
export const WITHDRAWAL_COMPLETION_UPDATE =
  'streaming:withdrawalCompletionUpdate';
export const MARKET_LINES = 'streaming:marketlines';
export const GPN_ORDER_RESULT = 'streaming:gpnOrderResult';
export const W2G_TAX_EVENT = 'streaming:W2GTaxEvent';
export const JACKPOT_WS = 'streaming:CasinoJackpotSubscriptionRequest';
import ats from 'ats';

class SportsSocketService extends SocketService {
  setWebsocketListener = () => {
    ats.accountWS.onMessage = this.parseMessage;
  };

  /**
   * @param data
   */
  parseMessage = data => {
    // log the message if debug flag set to true
    if (App.Globals.debug) {
      console.log(`Websocket :: Received - ${JSON.stringify(data)}`);
    }
    switch (true) {
      case !!data.AccountBalanceUpdate:
        App.socket.trigger(ACCOUNT_BALANCE_UPDATE, data.AccountBalanceUpdate);
        App.session.execute('set', {
          accountBalance: data.AccountBalanceUpdate.balance,
        });
        break;
      case !!data.BetUpdate:
        App.socket.trigger(BET_UPDATE, data.BetUpdate);
        break;
      case !!data.OverAskResponse:
        App.socket.trigger(OVER_ASK_RESPONSE, data.OverAskResponse);
        break;
      case !!data.PaymentRegistrationUpdate:
        App.socket.trigger(PAYMENT_REGISTRATION_UPDATE, data);
        break;
      case !!data.DepositCompletionUpdate:
        App.socket.trigger(DEPOSIT_COMPLETION_UPDATE, data);
        break;
      case !!data.CalculateCashoutResponse:
        var cashoutResult = data.CalculateCashoutResponse.cashoutResult;
        if (_.size(cashoutResult) > 0) {
          App.socket.trigger(CALCULATE_CASHOUT, cashoutResult);
        }
        break;
      case !!data.AccountRegistrationStatusUpdate: {
        App.socket.trigger(REGISTRATION_STATUS_UPDATE, data);
        break;
      }
      case !!data.VoucherMoneyVoucherUpdate:
        App.socket.trigger(
          VOUCHER_MONEY_UPDATE,
          data.VoucherMoneyVoucherUpdate,
        );
        break;
      case !!data.WithdrawalCompletionUpdate:
        App.socket.trigger(
          WITHDRAWAL_COMPLETION_UPDATE,
          data.WithdrawalCompletionUpdate,
        );
        break;
      case !!data.OpenbetsCount:
        App.socket.trigger(OPEN_BETS_COUNT_UPDATE, data.OpenbetsCount);
        break;
      case !!data.GpnOrderResult:
        App.socket.trigger(GPN_ORDER_RESULT, data.GpnOrderResult);
        break;
      case !!data.LogoffAccountEvent:
        App.session.execute('logout');
        break;
      case !!data.W2GEvent:
        App.socket.trigger(W2G_TAX_EVENT, data.W2GEvent);
        break;
      case !!data.Response?.JackpotsFeed:
        App.socket.trigger(JACKPOT_WS, data.Response?.JackpotsFeed);
        break;
      default:
        break;
    }
  };
}

const inst = new SportsSocketService();
export default inst;
