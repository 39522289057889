import { observable, action, computed, makeObservable } from 'mobx';
import store from 'store';
import { getCurrentSkin, getCurrentLangFolder } from 'app/util/ThemeUtils';
import { getParam } from 'common/util/Href';
import { STATE_CODES } from './util/StateUtil';
import viewportState from 'stores/ViewportState';
import debounce from 'lodash/debounce';
import ats from 'ats';
import Cookies from 'stores/CookieStore';
import menuState from 'stores/MenuState';

const getDefaultOdds = () => {
  const cached = Cookies.get('priceFormat');
  const defaultFormat = App.Features('defaults.oddsFormat');
  if (!cached) {
    return defaultFormat;
  }
  const match = App.Features('odds.types').find(odds => odds.code === cached);
  if (!match) {
    return defaultFormat;
  }
  return match.code;
};

class Globals {
  constructor() {
    makeObservable(this);
    document.addEventListener('dispatch', this.onBridgeDispatch);
    this.debug = document.URL.indexOf('debug=true') > -1;
    this.betsyncV3 = document.URL.indexOf('betsync=v3') > -1;
    this.useGetEvent = document.URL.indexOf('getevent=true') > -1;
    this.noGeoComply = document.URL.indexOf('geocomply=false') > -1;
    this.allowDevAnalytics =
      document.URL.indexOf('allowDevAnalytics=true') > -1;
    this.developerSettings =
      document.URL.indexOf('developerSettings=true') > -1;
    this.noMobileAppRequired = document.URL.indexOf('mobileApp=false') > -1;
    this.priceFormat = getDefaultOdds();
    this.setStateCode();
    this.init();
  }

  init = () => {
    this.loadFeatureWithStore(
      'asianLayout.enabled',
      'layout',
      'Asian',
      'European',
    );
    this.loadFeatureWithStore(
      'betslip.quickBetslip',
      'quickBetslipEnabled',
      true,
      false,
    );
    this.removeLoadingScreen = debounce(this._removeLoadingScreen, 200);

    if (this.developerSettings && this.isDevUat) {
      menuState.modals.developerSettings.open();
    }

    if (this.isMultiStateEnabled && !this.isPermittedState) {
      menuState.modals.permittedStateModal.open();
    }
  };

  brandCode = 'AMELCO';

  @observable
  sport = '';
  @observable
  zone = 'GMT';
  @observable
  locale = 'en-gb';
  @observable
  location = '';
  @observable
  forLayoutSegment = null;
  @observable
  site = '1';
  @observable
  autoAcceptLowerBetSlipPriceChanges = false;
  @observable
  isSportsbookAvailable = true;
  @observable
  debug = false;
  @observable
  layout = store.get('layout') === 'Asian' ? 'Asian' : 'European';
  @observable
  oddsBoost = false;
  @observable
  noGeoComply = true;
  @observable
  allowDevAnalytics = false;
  @observable
  noMobileAppRequired = true;
  @observable
  loaded = false;
  @observable
  externalPam = false;

  @observable
  quickBetslipEnabled = App.Features('betslip.quickBetslip');

  @computed
  get priceFormat() {
    return ats.config.priceFormat;
  }

  @computed
  get newBetslip() {
    return (
      App.Features('betslip.new') || document.URL.indexOf('betslip=new') > -1
    );
  }

  @computed
  get newOpenClosedBets() {
    return (
      App.Features('betslip.openClosedBets.new') ||
      document.URL.toLowerCase().indexOf('openclosedbets=new') > -1
    );
  }

  set priceFormat(format) {
    ats.config.setPriceFormat(format);
  }

  @computed
  get channelCode() {
    return ats.config.channel;
  }

  set channelCode(code) {
    ats.config.channel = code;
  }

  @computed
  get channel() {
    return ats.config.channelId;
  }

  set channel(id) {
    ats.config.channelId = id;
  }

  @computed
  get segment() {
    return ats.config.segment;
  }

  set segment(segment) {
    ats.config.segment = segment;
  }

  @computed
  get cms() {
    return ats.config.cms;
  }

  set cms(cmsCode) {
    ats.config.cms = cmsCode;
  }

  @computed
  get regionCode() {
    return ats.config.region;
  }

  set regionCode(region) {
    ats.config.region = region;
  }

  @computed
  get lang() {
    return ats.config.language;
  }

  set lang(lang) {
    ats.config.language = lang;
  }

  setStateCode() {
    const stateCode =
      this.env === 'prod'
        ? window.location.hostname.split('.')[0]
        : getParam('state')?.toUpperCase();

    const cachedStateCode = localStorage.getItem('stateCode');

    if (cachedStateCode && stateCode && cachedStateCode !== stateCode) {
      menuState.modals.stateCodeChange.open();
    }

    if (stateCode) {
      localStorage.setItem('stateCode', stateCode);
    }
  }

  @computed
  get stateCode() {
    return localStorage.getItem('stateCode');
  }

  @computed
  get state() {
    if (this.isMultiStateEnabled) {
      const states = App.Features('multiState.permittedStates') || [];
      return states.find(state => state.jurisdictionCode === this.stateCode);
    }
    return undefined;
  }

  @computed
  get isPermittedState() {
    const permittedStates = App.Features('multiState.permittedStates') || [];
    return permittedStates
      .map(({ jurisdictionCode }) => jurisdictionCode)
      .includes(this.stateCode);
  }

  @computed
  get isMultiStateEnabled() {
    if (this.isDevUat) {
      return (
        App.Features('multiState.enabled') || Cookies.get(`enable_multiState`)
      );
    }
    return App.Features('multiState.enabled');
  }

  @computed
  get isGeocomplyEnabled() {
    if (!this.isDevUat) return App.Features('geocomply.enabled');
    if (this.noGeoComply) {
      return false;
    }
    return (
      Boolean(Cookies.get('enable_geocomply')) ||
      App.Features('geocomply.enabled')
    );
  }

  @computed
  get developerSettingsEnabled() {
    return App.Globals.skin === 'amelco';
  }

  @computed
  get isAnalyticsEnabled() {
    if (!__DEV__ && !this.isDevUat) return true;
    if (this.allowDevAnalytics && this.isDevUat) {
      return true;
    }
    return false;
  }

  @computed
  get quickBetslip() {
    if (App.Features('betslip.quickBetslipForceMobile') && viewportState.md) {
      return true;
    }
    return this.quickBetslipEnabled;
  }

  @computed
  get isAsianLayout() {
    return this.layout === 'Asian';
  }

  @computed
  get defaultCurrency() {
    return App.Features(
      `currency.defaultCurrency.${this.lang.toLowerCase()}`,
      App.Features(
        `currency.defaultCurrency.default`,
        App.Features(`currency.defaultCurrency`, 'GBP'),
      ),
    );
  }

  get skin() {
    return getCurrentSkin();
  }

  get langFolder() {
    return getCurrentLangFolder();
  }

  get isDevUat() {
    if (App.Urls.domain.includes('prod')) {
      return false;
    }
    return /(-(dev|uat|usstg|lngrsgenius)\d{0,2}\.)?(amelcobetting\.com|abetting\.co|route53\.abetting\.co)$/.test(
      App.Urls.domain,
    );
  }

  get env() {
    if (this.isDevUat) {
      const regexp = /-((dev|uat|usstg)(\d{0,2})).(amelcobetting\.com|abetting\.co|route53\.abetting\.co)/;
      const match = App.Urls.domain.match(regexp);
      return match?.[1] || 'dev';
    }
    return 'prod';
  }

  set skin(skinName) {
    Cookies.set('skin', skinName);
    location.reload();
  }

  loadFeatureWithStore = (feature, storeId, onValue, offValue) => {
    if (App.Features(feature) && _.isUndefined(store.get(storeId))) {
      store.set(storeId, onValue);
      this[storeId] = onValue;
    } else if (!App.Features(feature)) {
      this[storeId] = offValue;
    }
  };

  /**
   * Handles dispatches from the AtsBridge
   */
  onBridgeDispatch = event => {
    const { context, callback, method } = event.data;
    if (context !== 'globals') return;
    if (callback) {
      callback(this[method]);
    }
  };

  /**
   * event : 'globals:localeChange'
   *
   * Sets the current locale. If no argument provided sets the default
   * @param locale
   */
  setLocale = (locale, silent) => {
    if (this.isNull(locale)) return false;
    locale = locale.replace(/^-+|-+$/, '');
    if (App.Features('globals.serverLocale')) {
      locale = App.Features('globals.serverLocale');
    }
    this._set('locale', locale.toLowerCase(), silent);
    return true;
  };

  /**
   * @param lang
   * @param silent
   * @returns {boolean}
   */
  setLang = (lang, silent) => {
    if (this.isNull(lang)) return false;
    this._set('lang', lang.toLowerCase(), silent);
    this.setLocale(
      `${lang.replace('-', '')}-${this.regionCode.toLowerCase()}`,
      true,
    );
    Cookies.set('ats_lang', lang);
    return true;
  };

  /**
   * @param segment
   */
  setSegment = (segment, silent = false) => {
    if (this.isNull(segment)) return false;
    this._set('segment', segment.toLowerCase(), silent);
    return true;
  };

  setCMS = (cmsCode, silent = false) => {
    if (this.isNull(cmsCode)) return false;
    this._set('cms', cmsCode.toLowerCase(), silent);
    return true;
  };

  /**
   * @param forLayoutSegment
   */
  setLayoutSegment = (segment, silent = false) => {
    if (this.isNull(segment)) return false;
    this._set('forLayoutSegment', segment.toLowerCase(), silent);
    return true;
  };

  /**
   * @param channelId
   */
  setChannel = (channel, silent = false) => {
    if (this.isNull(channel)) return false;
    this._set('channel', channel, silent);
    return true;
  };

  /**
   * @param regionCode
   */
  setRegionCode = (regionCode, silent = false) => {
    if (this.isNull(regionCode)) return false;
    this._set('regionCode', regionCode, silent);
    this.setLocale(
      `${this.lang.replace('-', '')}-${this.regionCode.toLowerCase()}`,
      true,
    );
    return true;
  };

  /**
   * event : 'globals:sportChange'
   *
   * Sets the current sport. If no argument provided sets the default
   * @param locale
   */
  setSport = (sport, silent) => {
    if (this.isNull(sport)) return false;
    const changed = this.sport !== sport;
    if (changed) {
      this.sport = sport;
    }
    return true;
  };

  /**
   * event : 'globals:autoAcceptLowerBetSlipPriceChanges'
   *
   * Sets the auto accept lower price change boolean. If no argument provided sets the default to false
   * @param format
   */
  setAutoAcceptLowerBetSlipPriceChanges = (
    autoAcceptLowerBetSlipPriceChanges,
    silent,
  ) => {
    if (this.isNull(autoAcceptLowerBetSlipPriceChanges)) return false;
    this._set(
      'autoAcceptLowerBetSlipPriceChanges',
      autoAcceptLowerBetSlipPriceChanges,
      silent,
    );
    return true;
  };

  /**
   * event : 'globals:priceFormatChange'
   *
   * Sets the current price format.
   * @param format
   */
  setFormat = (format, silent) => {
    if (this.isNull(format)) return false;
    format = format.toUpperCase();
    this._set('priceFormat', format, silent);
    // set sdk price format
    ats.config.priceFormat = format;

    Cookies.get(this.PRICE_FORMAT_COOKIE_NAME, format);
    return true;
  };

  /**
   * @returns {{locale: *, sport: *}}
   */
  get = () => ({ locale: this.locale, sport: this.sport });

  /**
   * Internal set method
   * @param key
   * @param val
   * @private
   */
  @action
  _set = (key, val, silent) => {
    const isSilent = silent !== false;
    const changed = this[key] !== val;
    if (this[key] !== val || !isSilent) {
      this[key] = val;
      App.bus.trigger(`globals:pre:${key}Change`, val, changed);
      App.bus.trigger(`globals:${key}Change`, val, changed);
    }
  };

  /**
   * Utility method to check for nullility
   * @param value
   * @returns {*}
   */
  isNull = value => _.isUndefined(value) || _.isNull(value);

  /**
   * @param sprt
   * @returns {*}
   * @constructor
   */
  Sport = (sprt = this.sport, replaceUnderscore = true) => {
    let sport;
    sport = sprt.toLowerCase();
    sport = sport == 'soccer' ? 'football' : sport;
    if (replaceUnderscore) {
      sport = sport.replace(/_/g, ' ');
    }
    return sport;
  };

  setLayout = value => {
    this.layout = value;
    store.set('layout', value);
  };

  @action
  toggle = (field, value = null) => {
    if (!this.hasOwnProperty(field)) {
      return;
    }
    this[field] = value === null ? !this[field] : value;
  };

  stillLoads = () => {
    this.removeLoadingScreen();
  };

  @action
  _removeLoadingScreen = () => {
    const loadingContainer = document.querySelector('.loading-container');
    if (loadingContainer && this.keyComponentLoaded) {
      loadingContainer.parentNode.removeChild(loadingContainer);
      document.body.classList.remove('loading-screen__loading');
      this.loaded = true;
    }
  };
}

export default Globals;
