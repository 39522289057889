import { observable, action, computed, observe, makeObservable } from 'mobx';
import { getAllUrlParams } from 'app/util/Utils';
import store from 'store';
import last from 'lodash/last';
import { toCurrentLang } from 'app/routes/translations';
import viewportState from 'stores/ViewportState';
import { ROUTES_MODALS } from 'app/AppConstants';
import menuState from 'stores/MenuState';
import trackingStore from 'app/stores/trackingStore';
import Cookies from 'stores/CookieStore';

import ats from 'ats';

const actions = ats.analytics.actions;

class Routing {
  @observable product = '';
  @observable checkAuth = false;
  @observable tabbedOutrights = false;
  @observable pathname = window.location.pathname;
  @observable previousPath = window.location.pathname;
  // will be called as a function if true in modals.
  explicitReturn = false;
  @observable hideBurger = false;
  @observable hideHeaderSettings = false;
  @observable routeParams = {};
  basePath = '/';
  utm = {};

  constructor() {
    makeObservable(this);
    const that = this;
    observe(this, 'pathname', ({ oldValue }) => {
      that.previousPath = oldValue || that.pathname;
      store.set('activePath', that.pathname);
      this.storeUTM();
    });
    this.storeUTM();
    window.onpopstate = this.onPopState;
    this.setPathName = _.debounce(this._setPathName, 50);
    this.setRouteParams = _.debounce(this._setRouteParams, 100);
  }

  storeUTM = () => {
    const { location } = window;
    Cookies.remove('UTM');
    if (location.search.length > 0) {
      const utm = getAllUrlParams(location.href);
      if (location.search.indexOf('utm_') > -1) {
        this.utm = utm;
        Cookies.set('UTM', utm);
      }
    }
  };

  setProduct = pathname => {
    ats.global.product = pathname?.toLowerCase().includes('casino')
      ? 'casino'
      : 'sportsbook';
  };

  @action
  _setPathName = pathname => {
    this.setProduct(pathname);
    this.previousPath = '';
    this.pathname = pathname;
    trackingStore.track(actions.navigate, {}, pathname);
    if (!App.isActive('home')) {
      App.Globals.keyComponentLoaded = true;
    }
  };

  @action
  _setRouteParams = routeParams => {
    this.routeParams = routeParams;
  };

  @computed
  get homeRoute() {
    const home = App.Features('routing.home') || 'home';
    return `${this.basePath}${home}`;
  }

  @computed
  get isInplay() {
    return this.product === 'live_betting';
  }

  @computed
  get hasBetslip() {
    if (App.viewport.isMobile && App.Features('mobile.bottomMenu.enabled')) {
      if (App.Features('japan')) {
        return this.checkBetslip;
      }
      return true;
    }
    if (App.Features('sportsDisabled')) {
      return false;
    }
    return this.checkBetslip;
  }

  @computed
  get checkBetslip() {
    const pathname = toCurrentLang(this.pathname);
    const focusPage = this.isParentRoute(
      [
        'login',
        'register',
        'passwordForgot',
        'passwordExpired',
        'passwordNew',
        'not-found',
      ],
      pathname,
    );

    if (App.Features('betslip.hideOn')?.includes(this.product)) {
      return false;
    }

    if (pathname === App.normalize(App.Features('routing.base'))) {
      return true;
    }

    if (last(pathname.split('/')) === toCurrentLang('esports')) {
      return true;
    }
    if (last(pathname.split('/')) === toCurrentLang('mybets')) {
      return true;
    }
    if (last(pathname.split('/')) === toCurrentLang('betswap')) {
      return true;
    }

    return (
      !focusPage &&
      [
        'sportsbook',
        'live_betting',
        'betslip',
        'arena',
        'virtuals',
        'social',
        'promotions',
      ].indexOf(this.product) > -1
    );
  }

  @computed
  get hasLoadingFlash() {
    return this.hasBetslip;
  }

  @computed
  get is404() {
    return this.product === '404';
  }

  @computed
  get hasBurger() {
    if (this.hideBurger) {
      return false;
    }
    if (this.isAccount && App.Features('account.standAlone.enabled')) {
      return false;
    }
    const focusPage = this.isParentRoute(
      [
        'login',
        'register',
        'passwordForgot',
        'passwordExpired',
        'passwordNew',
        'not-found',
        'welcome',
        'virtuals',
        'affiliates',
      ],
      this.pathname,
    );

    // burger can be turned of on mybets route via a feature toggle
    const mybetsDisabledBurger =
      !App.Features('accounts.myBets.leftNav') &&
      this.pathname.includes('mybets');

    if (
      ((this.isCasino || this.isTournament) &&
        !App.Features('alwaysShowLeftBurgerMenu')) ||
      this.isMahjong ||
      (App.Features('routing.disabledLeftNav.promotions') &&
        this.isPromotions) ||
      this.isFinancials ||
      this.isGameLobby ||
      this.isArena ||
      this.isRushGame ||
      this.isLottery ||
      this.isJackpot ||
      this.is404 ||
      this.isVendorGames ||
      this.isLuckyNumbers ||
      this.isAviatorGame ||
      this.isBetman ||
      focusPage ||
      mybetsDisabledBurger ||
      (!App.Features('betslip.inLeftNav') &&
        App.isActive('live/calendar', true))
    ) {
      return false;
    }

    return true;
  }

  @computed
  get isAccount() {
    return this.product === 'account';
  }

  @computed
  get isAccountRoute() {
    return (
      this.pathname.includes('/account/') || menuState.modals.account.isOpen
    );
  }

  @computed
  get isLoginRegister() {
    return this.isParentRoute(['login', 'register'], this.pathname);
  }

  @computed
  get isRegister() {
    return this.isParentRoute(['register'], this.pathname);
  }

  @computed
  get isCompetition() {
    return this.pathname.includes('/competition/');
  }

  @computed
  get isCategory() {
    return this.pathname.includes('/country/');
  }

  @computed
  get isEvent() {
    return this.pathname.includes('/event/');
  }

  @computed
  get isSportPage() {
    const { sport, lang } = App.Globals;
    return this.pathname.indexOf(`/${lang}/sports/${sport}`) > -1;
  }

  @computed
  get isArena() {
    return this.product === 'arena';
  }

  @computed
  get isVirtuals() {
    return this.product === 'virtuals';
  }

  @computed
  get isBetGames() {
    return this.product === 'betgames';
  }

  @computed
  get isCasino() {
    return (
      ['casino', 'live_casino', 'live_games', 'virtual', 'betgames'].indexOf(
        this.product,
      ) > -1
    );
  }

  @computed
  get isTournament() {
    return this.product === 'tournament';
  }

  @computed
  get isAffiliates() {
    return this.pathname.includes('affiliates');
  }

  @computed
  get isJustCasino() {
    return ['casino'].indexOf(this.product) > -1;
  }

  @computed
  get isLiveCasino() {
    return ['live_casino'].indexOf(this.product) > -1;
  }

  @computed
  get isCustomCasino() {
    if (!App.Features('routing.casino')) {
      return false;
    }
    return (
      this.pathname.split('/').pop() ===
      App.Features('routing.casino').substring(1)
    );
  }

  @computed
  get isLottery() {
    return ['lottoland'].indexOf(this.product) > -1;
  }

  @computed
  get isFinancials() {
    return this.product === 'financials';
  }

  @computed
  get isGameLobby() {
    return this.product === 'games';
  }

  @computed
  get isWelcome() {
    if (!App.Features('welcomePage.enabled')) {
      return false;
    }
    return this.pathname.includes('welcome');
  }

  @computed
  get isLive() {
    return this.product === 'live_betting';
  }

  @computed
  get isPromotions() {
    return ['promotions'].indexOf(this.product) > -1;
  }

  @computed
  get isRushGame() {
    return ['rush-game'].indexOf(this.product) > -1;
  }

  @computed
  get isJackpot() {
    return ['jackpot'].indexOf(this.product) > -1;
  }

  @computed
  get isMahjong() {
    return this.pathname.includes('mahjong');
  }

  @computed
  get isMyBets() {
    return this.pathname.includes('mybets');
  }

  @computed
  get isChalkline() {
    return this.product === 'chalkline';
  }

  @computed
  get isVendorGames() {
    return this.product === 'vendor-game';
  }

  @computed
  get isHome() {
    return /\/home|\/all|\/today/.test(this.pathname);
  }

  @computed
  get isUpdatePassword() {
    return this.isParentRoute(['passwordNew'], this.pathname);
  }

  @computed
  get isForgotPassword() {
    return this.isParentRoute(['passwordForgot'], this.pathname);
  }

  @computed
  get isWithdrawAccount() {
    return this.pathname.startsWith(`/${App.Globals.lang}/withdraw`);
  }

  @computed
  get isDepositAccount() {
    return this.pathname.startsWith(`/${App.Globals.lang}/deposit`);
  }

  @computed
  get isWithdrawRestrict() {
    return this.pathname.includes('restrict');
  }

  @computed
  get isCms() {
    return this.product === 'cms';
  }

  @computed
  get isLuckyNumbers() {
    return this.pathname.includes('lucky-numbers');
  }

  @computed
  get isAviatorGame() {
    return this.pathname.includes('aviator');
  }

  @computed
  get isBetman() {
    return this.pathname.includes('betman');
  }

  @action
  goBack = () => {
    // If the account modal is open - don't change the path, just close
    // the modal.
    if (
      menuState.modals?.account?.isOpen &&
      !App.Features('external.enabled')
    ) {
      menuState.modals.account.close();
      return;
    }
    if (
      !this.previousPath ||
      this.previousPath === this.pathname ||
      /\/event\/|\/competition\//.test(this.previousPath) ||
      App.Features('external.alwaysUseGoBack')
    ) {
      window.appHistory.goBack();
    } else {
      App.navigate(this.previousPath);
    }
  };

  @computed
  get hasSearch() {
    return (
      (App.Features('header.type') === 'default' ||
        App.Features('header.type') === 'styled' ||
        App.Features('header.type') === 'centered') &&
      !this.hideHeaderSettings &&
      !this.isWelcome &&
      this.hasBetslip
    );
  }

  @computed
  get customScrollbar() {
    return !(
      (this.isAccount && App.Features('japan')) ||
      this.hideBurger ||
      viewportState.lgxl ||
      this.product === 'mahjong' ||
      this.product === 'vendor-game' ||
      this.product === 'lottoland' ||
      this.product === 'betgames' ||
      /\/live\/results/.test(this.pathname) ||
      App.Features('customScrollbar.disabled')
    );
  }

  @computed
  get isLobbyRoute() {
    return this.pathname === '/';
  }

  @computed
  get isBundle() {
    return this.pathname.includes('bundles');
  }

  // check the route directly after the language code.
  isParentRoute = (routes, pathname) => {
    const routeActive = routes.some(route => {
      const splitPathname = pathname.split('/');
      if (
        splitPathname[2] &&
        splitPathname[2].toLowerCase() === toCurrentLang(route.toLowerCase())
      ) {
        return true;
      }
      return false;
    }, this);
    return routeActive;
  };

  getRouteModalObj = path => {
    const modalObj = ROUTES_MODALS.modals?.find(modal =>
      modal?.routes?.some(x => path.includes(toCurrentLang(x))),
    );
    return !!modalObj && modalObj;
  };

  isRoutingModal = path => {
    const modal =
      !App.Features('routing.modalRouteOverrides').find(route =>
        path.includes(route),
      ) && this.getRouteModalObj(path);
    return !!modal && App.Features(modal.featureCheck);
  };

  getRoutingModalType = path => {
    const modal = this.getRouteModalObj(path);
    if (!!modal && App.Features(modal.featureCheck)) {
      return modal.type;
    }
    return null;
  };
}

/* testing */
export const Class = Routing;

const routing = new Routing();
export default routing;
