import trackingStore from 'app/stores/trackingStore.js';
import ats from 'ats';

const TYPE_MAP = {
  login_clicked: 'Login Clicked',
  deposit_success: 'Deposit Succeeded',
  register_success: 'Registration Succeeded',
  betslip_remove: 'User Removed From Betslip',
  betslip_add: 'User Added To Betslip',
  casino_game_open: 'Casino Game Launched',
  user_registration_started: 'User Registration Started',
  carousel_banner_clicked: 'Carousel Banner Clicked',
  carousel_banner_viewed: 'Carousel Banner Viewed',
  customer_service_interaction: 'Customer Service Interaction',
};
class Segment {
  init = async () => {
    const pkg = await import('thirdparty/segment');
    if (pkg) {
      const initialise = pkg?.default;
      initialise(
        App.Features(
          `tracking.segment.key.${App.Globals.isDevUat ? 'dev' : 'prod'}`,
        ),
      );
    }
  };

  track = async (key, parameters = undefined) => {
    if (!TYPE_MAP[key]) {
      return;
    }
    if (!window.analytics) {
      await this.init();
    }
    if (key === 'navigate') {
      this.page();
    }
    window.analytics.track(TYPE_MAP[key], parameters);
  };

  identify = async (userId, traits) => {
    if (!window.analytics) {
      await this.init();
    }
    window.analytics.identify(userId, traits);
  };

  page = async () => {
    if (!window.analytics) {
      await this.init();
    }

    // wait for navigation, title change, etc...
    setTimeout(() => {
      window.analytics.page();
    }, 1000);
  };

  trackBetslip = (action, username, sel) => {
    const betslipMapper = ats.analytics.betslip.trackSelections(username, sel);
    trackingStore.track(action, betslipMapper, undefined, 'segment');
  };
}
const inst = __FEATURES__.tracking.segment.enabled && new Segment();
export default inst;
