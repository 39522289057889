import { action, computed, observable } from 'mobx';

export const TAG_KEYS = Object.freeze({
  LAST_BET_SPORT_TYPE: 'LastBetSportType',
  LAST_BET_DATE: 'LastBetDate',
  LAST_BET_SPORT_TIME: 'LastBetSportTime',
  LAST_MARKET_TYPE: 'LastMarketType',
  LAST_FREE_PLAY_BET: 'LastFreePlayBet',
  BALANCE: 'Balance',
  FIRST_DEPOSIT: 'FirstDeposit',
  LAST_LOGIN: 'LastLogin',
  LAST_DEPOSIT: 'LastDeposit',
  DEPOSIT_COMPLETED: 'DepositCompleted',
});

function createBetConfirmationTrackingData(betData) {
  const eventData = {
    GameName: '',
    MarketName: '',
    SelectionName: '',
    SportName: '',
    BetType: betData?.[0].betType,
    Value: betData?.[0].potentialPayout,
  };

  if (betData?.[0]?.type === 'SINGLE') {
    eventData.SportName = betData?.[0]?.betParts?.[0].betPart?.selection?.sport;
  }

  betData[0].betParts.forEach(betPart => {
    eventData.GameName += `${betPart.betPart?.selection?.eventName}, `;
    eventData.MarketName += `${betPart.betPart?.selection?.marketName}, `;
    eventData.SelectionName += `${betPart.betPart?.selection?.selectionName}, `;
  });

  return eventData;
}

const findRedeemBet = acceptedBets => {
  const bets = acceptedBets
    .filter(({ type }) => type === 'SINGLE')
    .map(({ betParts }) => betParts[0]);
  return bets.map(({ redeemFreeBet }) => redeemFreeBet).find(el => el);
};

const logFn = (tag, value) =>
  console.log(`OtherLevels - Tag set ${tag} ${value}`);

class OtherLevelsStore {
  @observable hasPermission = false;
  @observable canUseOtherLevels = false;
  @observable isPassBalance = false;

  init = () => {
    this.canUseOtherLevels = App.Features('analytics.otherLevels') && !!_ol;

    if (!this.canUseOtherLevels) return;

    _ol(
      'create',
      '3d3a6d1a40c0b1923c6967bd44535eb5',
      {
        safariWebsitePushId: 'web.com.action247.sportsbook',
      },
      () => {
        console.log('OtherLevels - Session started');
      },
    );
    this.askForPermission();
  };

  @action
  depositComplete = (depositAmount, depositChannel) => {
    this.registerEvent(TAG_KEYS.DEPOSIT_COMPLETED, {
      DepositAmount: depositAmount,
      DepositChannel: depositChannel,
    });
    this.setTimestampTag(TAG_KEYS.LAST_DEPOSIT);
  };

  @action
  askForPermission = () =>
    this._runWithOtherLevelExistChecking(this._askForPermission);
  @action
  passBalance = (...args) =>
    this._runWithOtherLevelExistChecking(...args, this._passBalance);
  @action
  passBetConfirmation = (...args) =>
    this._runWithOtherLevelExistChecking(...args, this._passBetConfirmation);
  @action
  setTrackingId = (...args) =>
    this._runWithOtherLevelExistChecking(...args, this._setTrackingId);
  @action
  registerEvent = (...args) =>
    this._runWithOtherLevelExistChecking(...args, this._registerEvent);
  @action
  setStringTag = (...args) =>
    this._runWithOtherLevelExistChecking(...args, this._setStringTag);
  @action
  setNumericTag = (...args) =>
    this._runWithOtherLevelExistChecking(...args, this._setNumericTag);
  @action
  setTimestampTag = (...args) =>
    this._runWithOtherLevelExistChecking(...args, this._setTimestampTag);
  @action
  endSession = (...args) =>
    this._runWithOtherLevelExistChecking(...args, this._endSession);

  _displayInterstitialHandler = () => {
    const fn = () => {
      console.log('OtherLevels - displayInterstitial running somewhere');
    };
    console.log('OtherLevels - run displayInterstitial');
    _ol('displayInterstitial', 'App Open', {}, fn, fn, fn);
  };

  displayInterstitial = () =>
    this._runWithOtherLevelExistChecking(this._displayInterstitialHandler);

  _setTrackingId = trackingID =>
    _ol('setTrackingId', trackingID, () => {
      console.log('OtherLevels - Tracking ID updated', trackingID);
    });

  _registerEvent = (eventType, eventLabel) =>
    _ol('registerEvent', eventType, eventLabel, () => {
      console.log('OtherLevels - Event registered', eventType, eventLabel);
    });

  _setStringTag = (tag, tagValue) =>
    _ol('setTag', tag, tagValue, 'string', () => logFn(tag, tagValue));

  _setNumericTag = (tag, tagValue) =>
    _ol('setTag', tag, tagValue, 'numeric', () => logFn(tag, tagValue));

  _setTimestampTag = (tag, tagValue) => {
    const time = tagValue ?? Math.round(+new Date() / 1000).toString();
    _ol('setTag', tag, time, 'timestamp', () => logFn(tag, time));
  };

  _endSession = () => {
    _ol('endSession', () => {
      this.init();
      console.log('OtherLevels - End session');
    });
  };

  @computed
  _runWithOtherLevelExistChecking = (...args) => {
    const fn = args[args.length - 1];
    this.canUseOtherLevels && fn(...args.slice(0, -1));
  };

  _askForPermission = () =>
    setTimeout(
      () =>
        _ol('askForPermission', 'notification', result => {
          console.log('OtherLevels - ask for permission', result);
          this.displayInterstitial();
        }),
      6000,
    );

  @computed
  _passBalance = (balance, isLogin) => {
    if (isLogin && this.isPassBalance) this._setNumericTag('Balance', balance);
    this.isPassBalance = isLogin;
  };

  @computed
  _passBetConfirmation = acceptedBetsCollection => {
    const betConfirmationTrackingData = createBetConfirmationTrackingData(
      acceptedBetsCollection,
    );

    this.registerEvent('BetConfirmation', betConfirmationTrackingData);

    this._setStringTag(
      TAG_KEYS.LAST_BET_SPORT_TYPE,
      acceptedBetsCollection?.[0]?.betParts?.[0].betPart?.selection?.sport,
    );

    this._setStringTag(
      TAG_KEYS.LAST_MARKET_TYPE,
      acceptedBetsCollection?.[0]?.betParts?.[0].betPart?.selection?.marketName,
    );

    this._passFreePlayerBet(acceptedBetsCollection);

    this._setTimestampTag(TAG_KEYS.LAST_BET_DATE);
    this._setTimestampTag(TAG_KEYS.LAST_BET_SPORT_TIME);
  };

  _passFreePlayerBet(acceptedBetsCollection) {
    const isRedeemFreeBet = findRedeemBet(acceptedBetsCollection);
    isRedeemFreeBet && this._setTimestampTag(TAG_KEYS.LAST_FREE_PLAY_BET);
  }
}

export default new OtherLevelsStore();
