import CryptoJS from 'crypto-js';

class UniqueDeviceIDStore {
  constructor() {
    this.uniqueDeviceID = this.generateHash();
  }
  generateHash() {
    const { cookieEnabled, language, platform, userAgent } = navigator;
    const screenSize = `${window.screen.width}x${window.screen.height}`;
    const plugins = Array.from(navigator.plugins)
      .map(p => `${p.name}-${p.version}`)
      .join(';');
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const screenOrientation = window.screen.orientation?.angle || '';
    const colorScheme = window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? 'dark'
      : 'light';
    const additionalBits = `${language}${platform}${plugins}${cookieEnabled}${timezone}${screenOrientation}${colorScheme}`;
    const hash = CryptoJS.MD5(
      `${userAgent}${screenSize}${additionalBits}`,
    ).toString();
    return hash;
  }
}

const store = new UniqueDeviceIDStore();
export default store;
